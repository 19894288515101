<template lang="pug">
include ../Bemto
+b.auth-modal
  row(
    appearance="spaced"
    justify="center"
  )
    cell(cols="12")
      row(
        appearance="spaced"
        justify="center"
      )
        cell(cols="narrow")
          +b.IMG.block.ma--t-sm(
            width="50"
            src="/static/img/raising-hands.png"
          )
    cell(cols="12 8-md")
      +b.P.text.--align-center.--weight-bold.--size-lg-sm.--color-black.--font-serif.ma--t-2sm {{ _('Вітаю, особистий кабінет створено!') }}
    cell(cols="12 6-md")
      +b.P.text.--font-serif.--align-center.--size-sm.--color-dark-gray.ma--t-2xxs.ma--b-xss {{ _('Твоя анкета буде доступна для замовників пiсля додавання кастинг-директором фото та відео, які ми зробимо в нашій студії.') }}
    cell(cols="12 7-md")
      +b.P.text.--align-center.--size-sm.--color-dark-gray.ma--t-2xs.ma--b-sm {{ _('Переглянути свою анкету, додати свої фото, посилання на свої соціальні мережі можна вже зараз в особистому кабінеті.') }}
    cell(cols="12 7-md")
      //- +b.P.text.--align-center.--size-sm.--color-dark-gray.ma--t-2xs.ma--b-lg {{ _('Переглянути свою анкету, додати свої фото, посилання на свої соціальні мережі можна вже зараз в особистому кабінеті.') }}
      +b.A.el-btn.--default.ma--b-lg(
        href="/auth/login/"
      )
        +b.P.text.--color-white.--space-lg.--size-1sm.--transform-upper.--weight-bold {{ _('До кабінету') }}
  +b.close-modal(
    @click.prevent='$emit("close")'
  )
    +b.APP-ICON(name="icon-cross")
</template>

<script>

// import ChatBotModal from '@app/modals/ChatBotModal'

export default {
  // mounted() {
  //   this.$once('hook:beforeDestroy', () => {
  //     this.$modal.show(ChatBotModal)
  //   })
  // },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>
