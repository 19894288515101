<template lang="pug">
include ../../Bemto
ui-click-outside(:do='hideUserBar')
  +b.userbar(
    :class="{'is-active': isActive}"
  )
    +e.heading(
      @click='toggleUserBar'
    )
      +e.items--icon
        ds-inliner(
          size="2xl"
        )
          icon-user-circle(
            width="21"
            height="21"
          )

      +e.items--text
        +b.P.text--size-sm {{ name }}
      +e.chevron
        ds-inliner(
          size="md"
        )
          icon-chevron-down(
            width="100%"
            height="100%"
          )

    +e.menu-wrap(
      v-if='isActive'
    )
      +e.menu
        +e.A.link.text.--color-black.--weight-medium.--pointer-green.--size-sm(
          :href='cabinet'
        )
          app-icon(name="icon-home" size="xl")
          +e.SPAN.text.ma--l-xs {{ _('Cabinet') }}
        +e.A.link.text.--color-black.--weight-medium.--pointer-green.--size-sm(
          v-if="'admin' != role"
          :href='cabinet+ "profile/settings/"'
        )
          app-icon(name="icon-settings" size="xl")
          +e.SPAN.text.ma--l-xs {{ _('Налаштування') }}
        //- template(
        //-   v-if="'admin' == role"
        //- )
          +e.A.link.text.--color-black.--weight-medium.--pointer-green.--size-sm(
            :href='cabinet+ "layout/projects/"'
          )
            app-icon(name="icon-menu-star" size="xl")
            +e.SPAN.text.ma--l-xs {{ _('Проекти') }}
        +e.A.link.text.--color-black.--weight-medium.--pointer-green.--size-sm(
          :href='logout'
        )
          app-icon(name="icon-logout" size="xl")
          +e.SPAN.text.ma--l-xs {{ _('Logout') }}
</template>

<script>
export default {
  name: 'el-userbar',
  props: {
    avatar: {},
    role: {},
    cabinet: String,
    logout: String,
    name: String,

  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleUserBar() {
      this.isActive = !this.isActive
    },
    hideUserBar() {
      this.isActive = false
    },
  },
}
</script>
