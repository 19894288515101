<template lang="pug">
include ../Bemto
div
  //- pre {{ preparedFilters }}
  +b.i-panel.--variant_1(v-for='filter in preparedFilters')
    ui-dropdown(:initial-active="isDesktop ? !filter.isClosed : false")
      template(#default='{ isActive, toggle }')
        +b.accordion
          +e.item(:class="{'is-active': isActive}")
            +e.heading.text.--pointer(@click.prevent="toggle")
              +e.title
                +b.P.text.--weight-bold.--size-sm.--size-2sm-lg.--font-gotham {{ filter.label }}
              +e.icon(
                :class="{'active': isActive}"
              )
                +b.APP-ICON.icon(name="icon-sideleft")
            +b.ds-panel.--space_lg(v-if="isActive")
              +e.element.--offset_top
                tag(
                  :tag="widgetsMap[filter.type]"
                  :value="'range' === filter.type ? getMultipleValues(filter.name) : value[filter.name]"
                  :filter-name="filter.name"
                  :filter="filter"
                  @input="'range' === filter.type ? updateMultipleValues(filter.name, $event) : updateValue(filter.name, $event)"
                )
  +b.i-panel.--variant_1(v-if='hasOtherFilter')
    ui-dropdown(:initial-active="isDesktop ? true : false")
      template(#default='{ isActive, toggle }')
        +b.accordion
          +e.item(:class="{'is-active': isActive}")
            +e.heading.text.--pointer(@click.prevent="toggle")
              +e.title
                +b.P.text.--weight-bold.--size-sm.--size-2sm-lg.--font-gotham {{ _("Інше") }}
              +e.icon(
                :class="{'active': isActive}"
              )
                +b.APP-ICON.icon(name="icon-sideleft")
            +b.ds-panel.--space_lg(v-if="isActive")
              +e.element.--offset_top
                tag(
                  :tag="widgetsMap['other-filters']"
                  :value="getOtherValue()"
                  @input="updateOtherValue($event)"
                )
                //- :filter="filter"
</template>

<script>
export default {
  props: {
    isDesktop: Boolean,
    preparedFilters: {},
    updateMultipleValues: {
      type: Function,
    },
    getMultipleValues: {
      type: Function,
      default: v => v,
    },
    getOtherValue: Function,
    updateOtherValue: Function,
    updateValue: {
      type: Function,
    },
    widgetsMap: {
      type: Object,
    },
    value: {},
    hasOtherFilter: () => false,
  },
  methods: {
  },
}
</script>
