<template lang="pug">
include ../../Bemto
//- ui-click-outside(:do='hideBar')
div(
  :key="id"
)
    //- slot
    +b.project-bar
      +e.wrapper
        +b.P.text.--size-2sm.--weight-bold.--font-gotham {{ _('Add to projects') }}
        +b.close-bar(
          v-close-popover.all
        )
          +b.APP-ICON.text.--color-gray.--size-2lg.--pointer-green(
            name="icon-cross"
            @click="hideBar"
          )
        +e.INPUT.bar-input.ma--t-lg(
          :placeholder="_('Search project')"
          v-model='filters.name'
        )
        +b.accordion-limiter.ma--t-xs
          div.ma--t-xs
            div.ma--l-sm
              div.ma--t-xs(
                v-for='(item, index) in filteredProjectsList'
              )
                checkbox-bar(
                  :name='`${id}_${item.id}_${index}_${filters.name}`'
                  :key='`${id}_${item.id}_${index}_${filters.name}`'
                  :message='item.name'
                  :set-value='setCheckboxValue(id, item)'
                  @checkbox-data="projectEventHandler($event, item)"
                )
      div(
        :style="{padding: '15px', borderTop: '1px solid #ccc'}"
      )
        +b.BUTTON.el-btn.--default(
          @click='hideBar'
          v-close-popover.all
        )
          +b.P.text.--size-sm.--color-white.--transform-upper.--space-lg {{ _('Зберегти') }}
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import {
  addTalentsToProjectAPI,
  removeTalentsFromProjectApi,
} from '@requests/services/services'

export default {
  name: 'project-bar',
  props: ['id'],
  data() {
    return {
      searchProjectFn: null,
      createDraft: false,
      filters: {
        name: '',
      },
    }
  },
  computed: {
    ...mapGetters('unionProjectsList', {
      projectsList: 'getActiveProjectsList',
    }),
    filteredProjectsList() {
      return this.projectsList.filter(el => -1 !== el.name.toLowerCase().indexOf(this.filters.name.toLowerCase()))
    },
  },
  methods: {
    ...mapActions('unionProjectsList', [
      'projectListRequest',
    ]),
    hideBar() {
      this.$emit('close-bar')
      this.createDraft = false
    },
    setCheckboxValue(id, project) {
      return !!(project.talents || []).find(el => el.id === id)
    },
    projectEventHandler(e, item) {
      if (e) {
        this.addToProject(this.id, item)
      } else {
        this.removeFromProject(this.id, item)
      }
    },
    addToProject(id, item) {
      const params = {
        ids: [id],
      }
      addTalentsToProjectAPI.execute({ id: item.id }, params).then(() => {
        this.projectListRequest()
      })
    },
    removeFromProject(id, item) {
      const params = {
        ids: [id],
      }
      removeTalentsFromProjectApi.execute({ id: item.id }, params).then(() => {
        this.projectListRequest()
      })
    },
  },
}
</script>
