import Vuex from 'vuex'
import { currentUser } from './modules/current-user/current-user'
import { favoriteList } from './modules/favorite-list/favoriteList'
import { countriesList } from './modules/countries-list/countries-list'
import { projectSelects } from './modules/project-selects/project-selects'
import { unionProjectsList } from './modules/union-projects-list/union-projects'
import { talentCharacteristics } from './modules/characteristics/characteristics'

export default function crateStore() {
  return new Vuex.Store({
    modules: {
      currentUser,
      favoriteList,
      countriesList,
      projectSelects,
      unionProjectsList,
      talentCharacteristics,
    },
  })
}
