<template lang="pug">
include ../../Bemto
+b.talent-card
  +e.A(
    :href="talent.link"
  )
    +e.card-avatar
      template(v-if="talent.firstPreviewImage && talent.firstPreviewImage.previewBase")
        +e.PICTURE.main-avatar(
          v-if="talent.firstPreviewImage.previewBase "
        )
          +e.SOURCE(:srcset="talent.firstPreviewImage.previewWebp")
          +e.IMG.photo(:src="talent.firstPreviewImage.previewBase", :alt='talent.firstName' loading="lazy")
        +e.second-avatar(
          v-if="talent.secondPreviewImage.previewBase "
        )
          picture
            +e.SOURCE(:srcset="talent.secondPreviewImage.previewWebp")
            +e.IMG.photo(:src="talent.secondPreviewImage.previewBase", :alt='talent.firstName' loading="lazy")

      +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName')
  +e.data
    row(
      :justify="'between'"
      :align="'center'"
    )
      cell
        +b.A.text.--weight-bold.--font-serif-.--size-lg-lg.--color-black.--pointer-green(
          :href="talent.link"
        ) {{ talent.firstName }}
      cell(
        v-if='!recently'
      )
        row(
          :align="'center'"
        )
          cell(v-if="isAdmin")
            v-popover(
              :popover-class="'tooltip-modal'"
              :delay="{ show: 500, hide: 500 }"
              :key='talent.id'
            )
              +b.APP-ICON.text.--pointer-green(
                :size="['md', 'xl-md']"
                name="icon-star"
              )
              template(#popover)
                project-bar.card-projects-bar(
                  :key='talent.id'
                  :id='talent.id'
                )
          cell(v-if="isAdmin")
            favorites-bar(
              :id='talent.id'
              :key='talent.id'
              v-slot="{ isFavorite, toggle }"
            )
              .card-favorites-bar(
                :class="{ 'is-active': isFavorite }"
              )
                +b.APP-ICON.text.--pointer-green(
                  :size="['lg','2xl-md']"
                  name="icon-heart"
                  @click.prevent="toggle()"
                )
          cell
            modal-trigger(
              name='TalentPreview'
              url='modals/TalentPreview'
              :classes="['vm--modal_xxl']"
              :talent='talent'
              :isAdmin="isAdmin"
            )
              template(#default='{ open }')
                span(
                  @click.prevent='open'
                )
                  +b.APP-ICON.text.--pointer-green(
                    :size="['md', 'xl-md']"
                    name="icon-eye-on"
                  )
    row.card-data
      cell
        +b.P.text.--size-sm.--size-md-lg {{ _('ID: ') + talent.id }}
      cell
        +b.delimiter
      cell
        +b.P.text.--size-sm.--size-md-lg {{ _('Age: ') + talent.age }}
    row.card-data.card-tags(
      appearance="spaced"
      space="xs"
      :align="'center'"
    )
      cell(
        v-for="(tag, index) in talent.specials"
        :key='`specials_${tag}_${index}`'
      )
        +b.SPAN.card-tag.text.--size-2xxs.--size-xs-md(
          v-if="characteristic"
        ) {{ characteristic('specials', tag) }}
      cell(
        v-for="(tag, index) in talent.skills"
        :key='`skills_${tag}_${index}`'
      )
        +b.SPAN.card-tag.text.--size-2xxs.--size-xs-md(
          v-if="characteristic"
        ) {{ characteristic('skills', tag) }}
    row.ma--t-xs(
      v-if='recently'
      :align="'center'"
    )
      cell(v-if="isAdmin")
        v-popover(
          :popover-class="'tooltip-modal'"
          :delay="{ show: 500, hide: 500 }"
          :key='talent.id'
        )
          +b.APP-ICON.text.--pointer-green(
            size="xl"
            name="icon-star"
          )
          template(#popover)
            project-bar.card-projects-bar(
              :key='talent.id'
              :id='talent.id'
            )
      cell(v-if="isAdmin")
        favorites-bar(
          :id='talent.id'
          :key='talent.id'
          v-slot="{ isFavorite, toggle }"
        )
          .card-favorites-bar(
            :class="{ 'is-active': isFavorite }"
          )
            +b.APP-ICON.text.--pointer-green(
              size="2xl"
              name="icon-heart"
              @click.prevent="toggle()"
            )
      cell
        modal-trigger(
          name='TalentPreview'
          url='modals/TalentPreview'
          :classes="['vm--modal_xxl']"
          :talent='talent'
          :isAdmin="isAdmin"
        )
          template(#default='{ open }')
            span(
              @click.prevent='open'
            )
              +b.APP-ICON.text.--pointer-green(
                size="xl"
                name="icon-eye-on"
              )
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'talent-card',
  props: {
    talent: Object,
    recently: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['isAdmin'],
  data() {
    return {
      starActive: false,
      hover: false,
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getSingleCharacteristic',
    }),
  },
  methods: {
  },
}
</script>
