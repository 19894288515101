<template lang="pug">
include ../Bemto
+b.verification-wrapp
  LoadingWrapper(:loading="isLoad")
    +e.wrap(
      v-show='!verification'
    )

      +b.P.text.--weight-bold.--font-serif.--color-black.--size-2lg.--size-xl-sm.pa--b-2sm {{ _("Let's get acquainted") }}
      +e.VALIDATION-OBSERVER.form(
        ref='validator'
        v-slot="{ valid }"
        tag='form'
      )
        validation-provider(
          vid="recaptcha"
          v-slot="{ errors }"
        )
          captcha.control-descriptor--clear(
            v-model='form.recaptcha'
            ref='captcha'
            @verify='saveCaptcha($event)'
          )
          +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
            v-if='errors && errors.length'
          ) {{ errors[0] }}
        d-control-input(
          :style="{marginTop: '-25px'}"
          :class="'register-input'"
          :rules="'required'"
          v-model='form.name'
          name='name'
          :placeholder="_('Name')"
        )
        d-control-input(
          :style="{marginTop: '-25px'}"
          :class="'register-input'"
          v-model='form.email'
          name='email'
          :rules="'required|email'"
          :placeholder="_('Email')"
        )
        +b.P.text.--color-gray.--align-center.--weight-default.--size-sm.ma--b-2sm {{ _('Need for verification') }}
        +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.--transform-upper.--weight-bold(
          @click.prevent="awaitCaptcha"
        ) {{ _('Go!') }}
        +b.P.social-auth-title.text.--color-black.--align-center.--transform-upper.--size-sm {{ _('or') }}
          row.ma--t-sm
            cell(
              :cols="'12'"
            )
              +b.A.social-auth.--google(
                :href='googleLink'
              )
                img(src='/static/img/google.png')
            //- cell(
            //-   :cols="'6'"
            //- )
              +b.A.social-auth.--facebook(
                :href='facebookLink'
              )
                +b.APP-ICON.text.--color-white(
                  name="icon-facebook-square"
                )
        row.to-login.ma--t-lg.pa--t-sm(
          :justify="'center'"
          :align="'center'"
        )
          cell
            +b.P.text.--size-sm {{ _('Already have account ?') }}
          cell
            +b.A.text.--color-light-blue.--size-sm.co-link(
              :href='logLink'
            ) {{ _('Log in') }}
    confirm-email(
      v-show='verification'
      @send-again="awaitCaptcha"
      :user-data='form'
    )
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { verificationAPI } from '@requests/services/services'

const Captcha = () => import('@app/Forms/Captcha')

export default {
  mixins: [
    FormMixin,
  ],
  components: { Captcha },
  props: {
    googleLink: {},
    facebookLink: {},
    logLink: {},
  },
  name: 'verification',
  data() {
    return {
      verification: false,
      form: {
        name: null,
        email: null,
        recaptcha: '',
      },
    }
  },
  methods: {
    saveCaptcha(captcha) {
      this.form.recaptcha = captcha
      return this.prepareData()
    },
    updateValidator(errors) {
      this.$refs.captcha.reset()
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    awaitCaptcha() {
      if (this.$refs.captcha) {
        this.$refs.captcha.reload()
      }
    },
    prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return null;
        return this.submit(valid, this.form)
      });
    },
    send(data) {
      const formdata = JSON.parse(JSON.stringify(data))

      formdata.email = formdata.email.toLowerCase()

      return verificationAPI.execute({}, formdata).then(() => {
        if (this.verification) {
          this.$notify(
            {
              text: this._('Email sended'),
              type: 'success',
            }
          )
        }
        this.verification = true
        this.isLoad = false
        this.$refs.captcha.reset()
      })
    },
  },
}
</script>
