import { getProjectsPreviewListAPI, addToProjectFromFavoriteAPI } from '@requests/services/services'

export const unionProjectsList = {
  namespaced: true,
  state: {
    projects: [],
  },
  getters: {
    getProjectsList: state => state.projects,
    getActiveProjectsList: state => state.projects.filter(({ status }) => 0 === status),
  },
  actions: {
    projectListRequest({ commit }) {
      return getProjectsPreviewListAPI.execute().then(({ data: { items } }) => {
        commit('setUnionList', items)
      })
    },
    async addTalentProject(state, payload) {
      await addToProjectFromFavoriteAPI.execute({}, payload)
    },
  },
  mutations: {
    // eslint-disable-next-line
    setUnionList: (state, response) => state.projects = response,
    addNewList: (state, response) => state.projects.push(response),
  },
}
