<template lang="pug">
include ../../Bemto
+b.rectangle-radio-block
  slot
  +e.P.error(
    v-if='errorText && !value'
  ) {{ errorText }}
  row(
    :space='rowSpace'
    v-bind="rowAttributes"
  )
    cell(
      v-for="(item, index) in radioData"
      :key='index'
      :cols="colsArray"
    )
      +b.rectangle-radio-item
        +e.hidden-input.INPUT(
          type='radio'
          v-model='value'
          :value='item.value'
          :name='name'
          @change="$emit('radio-value', value)"
          :id='name + index'
        )
        +e.LABEL.fake-label(
          :for='name + index'
        )
          +b.text
            p {{ item.displayName }}
            p(
              v-if="item.additionalInfo"
            ) {{ item.additionalInfo }}
        +b.tooltip-block.--skills(
          @click.stop.self.prevent
          v-tooltip="{ content: item.tooltip, placement: 'top-center', triggers: ['hover', 'focus', 'touch'] }"
          v-if="item.tooltip && item.tooltip.length"
        )
          +e.icon-wrap
            +e.icon(
              @click.stop.self.prevent
            )
              +b.P.text.--color-light-blue.--weight-bold i
</template>

<script>
export default {
  name: 'radio-rectangle',
  props: {
    rowSpace: {
      type: Array,
      default: () => (['xl']),
    },
    setValue: {},
    errorText: String,
    name: String,
    radioData: Array,
    colsArray: Array,
    rowAttributes: {},
  },
  data() {
    return {
      value: null,
    }
  },
  watch: {
    setValue(newVal) {
      if (undefined !== newVal) {
        this.value = this.setValue
      }
    },
  },
  mounted() {
    if (undefined !== this.setValue) {
      this.value = this.setValue
    }
  },
}
</script>
