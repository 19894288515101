<template lang="pug">
include ../../Bemto
div(v-if="talent")
  +b.favorite-card-desktop
    +e.photo
      +e.A.link(
        :href="talent.link"
      )
        picture(
          @mouseover="talent.secondPreviewImage.base ? hover = true : hover = false"
          @mouseleave="hover = false"
        )
          template(v-if="talent.firstPreviewImage.base")
            template(v-if="talent.secondPreviewImage.base ")
              +e.SOURCE(:srcset="hover ? talent.secondPreviewImage.webp : talent.firstPreviewImage.webp")
              +e.IMG.photo(:src="hover ? talent.secondPreviewImage.base : talent.firstPreviewImage.base", :alt='talent.firstName')
            template(v-else)
              +e.SOURCE(:srcset="talent.firstPreviewImage.webp")
              +e.IMG.photo(:src="talent.firstPreviewImage.base", :alt='talent.firstName')
          +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName')
        +b.favorite-checkbox
          +e.INPUT.checkbox.none--xs(
            type='checkbox'
            :name='talent.id'
            :id='talent.id'
            v-model='value'
            @change="$emit('mark-data', value, talent.id)"
          )
          +e.LABEL.fake-checkbox(
            :for='talent.id'
          )
    +e.data.pa--round-sm
      row(
        :justify="'between'"
        align="center"
      )
        cell(:cols="[]")
          +b.A.text.--font-serif.--color-black.--size-lg.--weight-bold.--pointer-green(
            :href="talent.link"
          ) {{ talent.firstName }}
        cell(:cols="[]")
          row(align="center")
            cell(:cols="[]")
              v-popover(
                :popover-class="'tooltip-modal'"
                :delay="{ show: 500, hide: 500 }"
                :key='talent.id'
              )
                +b.APP-ICON.text.--size-2lg.--pointer-green(
                  tag="div"
                  size="xl"
                  name="icon-star"
                )
                template(#popover)
                  project-bar.card-projects-bar(
                    :id='talent.id'
                    :key='talent.id'
                  )
            cell(:cols="[]")
              modal-trigger(
                name='TalentPreview'
                url='modals/TalentPreview'
                :classes="['vm--modal_xxl']"
                :talent='talent'
              )
                template(#default='{ open }')
                  div(
                    @click.prevent='open'
                  )
                    +b.APP-ICON.text.--pointer-green(
                      tag="div"
                      size="xl"
                      name="icon-eye-on"
                    )
      row.card-data
        cell
          +b.P.text.--size-sm {{ _('ID: ') + talent.id }}
        cell
          +b.delimiter
        cell
          +b.P.text.--size-sm {{ _('Age: ') + talent.age }}
      row.ma--t-2xs(
        v-if='characteristic'
      )
        cell.ma--b-xs(
          v-for='skill, index in talent.skills'
          :key='index'
        )
          +b.panel.--bg-light-gray.--br-xl.pa--round-2xxs
            +b.P.text.--size-xs {{ characteristic('skills', skill) }}

</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'favorite-card',
  props: {
    talent: {},
    state: {},
  },
  data() {
    return {
      hover: false,
      value: null,
      visibleProjectsList: false,
      modalDataTalent: {},
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getSingleCharacteristic',
    }),
  },
  watch: {
    state() {
      this.value = this.state
    },
  },
  methods: {
  },
}
</script>
