<template lang="pug">
include ../Bemto
+b.verification-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap
  +b.P.text.--align-center.--size-sm.--color-dark-gray(
  ) {{ _('Enter the email and password you specified during registration') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '12px'}"
    ref='validator'
    v-slot="{ handleSubmit, valid }"
    slim
  ): form(@submit.prevent='handleSubmit(awaitCaptcha)')
    validation-provider(
      vid="recaptcha"
      v-slot="{ errors }"
    )
      captcha.control-descriptor--clear(
        v-model='form.recaptcha'
        ref='captcha'
        @verify='saveCaptcha($event)'
      )
      +b.P.control-descriptor__errors.ma--t-xs.text.--align-center(
        v-if='errors && errors.length'
      ) {{ errors[0] }}
    d-control-input(
      autofocus
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|email'"
      v-model='form.email'
      type='email'
      name='email'
      :placeholder="_('Email')"
    )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      v-model='form.password'
      name='password'
      type='password'
      :rules="'required'"
      :placeholder="_('Password')"
    )
    +b.P.text.--align-center.--color-red.--size-sm(
      v-if='nonFieldErrors'
    ) {{ nonFieldErrors[0].message }}
    modal-trigger(
      name='ForgotPassword'
      url='modals/ForgotPassword'
    )
      template(#default='{ open }')
        +b.P.forgot-password.text.--color-light-blue.--align-center.--size-sm.--pointer-black(
          @click='open'
        ) {{ _('Forgot password ?') }}
    +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.--transform-upper.--weight-bold(
      @click.prevent="awaitCaptcha"
      type='submit'
      :disabled='isLoad'
    ) {{ _('Sign in') }}
    +b.P.social-auth-title.text.--color-black.--align-center.--transform-upper.--size-sm {{ _('or') }}
      row.ma--t-sm(
        justify='center'
      )
        cell(
          :cols="'12'"
        )
          +b.A.social-auth.--google(
            :href='googleLink'
          )
            img(src='/static/img/google.png')
        //- cell(
        //-   :cols="'6'"
        //- )
          +b.A.social-auth.--facebook(
            :href='facebookLink'
          )
            +b.APP-ICON.text.--color-white(
              name="icon-facebook-square"
            )
    div(
      v-for='item in authMessages'
    )
      +b.P.text.--align-center.--color-red.--size-sm {{ item }}
    //- row.to-login.ma--t-xs.pa--t-sm(
    //-   :justify="'center'"
    //-   :align="'center'"
    //- )
    //-   cell
    //-     +b.P.text.--size-sm.--color-black {{ _('Have no account?') }}
    //-   cell
    //-     +b.A.text.--size-sm.--color-light-blue.co-link(
    //-       :href='regLink'
    //-     ) {{ _('Registration') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { signInAPI } from '@requests/services/services'

const Captcha = () => import('@app/Forms/Captcha')

export default {
  name: 'sign-up',
  mixins: [
    FormMixin,
  ],
  components: { Captcha },
  props: {
    regLink: {},
    googleLink: {},
    facebookLink: {},
    authMessages: {
      default: () => ([]),
    },
    nextUrl: String,
  },
  data() {
    return {
      nonFieldErrors: null,
      form: {
        email: null,
        password: null,
      },
    }
  },
  methods: {
    saveCaptcha(captcha) {
      this.form.recaptcha = captcha
      return this.prepareData()
    },
    updateValidator(errors) {
      this.$refs.captcha.reset()
      this.nonFieldErrors = errors.nonFieldErrors
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    awaitCaptcha() {
      if (this.$refs.captcha) {
        this.$refs.captcha.reload()
      }
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return null;
        return this.submit(valid, this.form)
      });
    },
    send(data) {
      // this.$load(signInAPI.execute({}, data)).then(() => {
      //   this.isLoading = false
      //   window.location.href = redirect
      // })
      //   .catch(() => {
      //     this.isLoading = false
      //   })
      const formdata = JSON.parse(JSON.stringify(data))

      formdata.email = formdata.email.toLowerCase()

      return signInAPI.execute({}, formdata).then(({ data: { item: { redirect } } }) => {
        this.$refs.captcha.reset()
        if (this.nextUrl) {
          window.location.href = this.nextUrl
        } else {
          window.location.href = redirect
        }
      })
      // .catch(() => {
      //   this.isLoading = false
      // })
    },
  },
}
</script>
