<template lang="pug">
  include ../Bemto

  div
    +b.i-filter-modal-trigger
      +e.BUTTON.button(
        type="button"
        @click="showFilterModal()"
      )
        | {{ _("Фільтри") }}
        .ma--l-xs
          app-icon(name="icon-filter" size="lg")
    component(
      v-bind="isDesktop ? {} : modalAttrs"
      :is="isDesktop ? 'div' : 'modal'"
    )
      +b.close-modal(
        @click.prevent='hideFilterModal()'
      )
        +b.APP-ICON(name="icon-cross")
      filters-renderer.i-filter(
        :is-desktop="isDesktop"
        :widgets-map="$options.widgetsMap"
        :prepared-filters="filters"
        :update-multiple-values="updateMultipleValues"
        :get-multiple-values="getMultipleValues"
        :getOtherValue="getOtherValue"
        :updateOtherValue="updateOtherValue"
        :update-value="updateValue"
        :value="value"
        :hasOtherFilter="hasOtherFilter"
      )
      +b.i-filter-apply-action
        +b.BUTTON.el-btn--fill-black(
          type="button"
          @click.prevent="() => { $emit('input', {}); hideFilterModal(); }"
        ) {{ _("Усунути фільтри") }}
        +b.BUTTON.el-btn--fill(
          type="button"
          @click.prevent='hideFilterModal()'
        ) {{ _("Показати результати") }}
</template>

<script>
import UiMixin from './mixins/UiMixin'
import FiltersRenderer from './FiltersRenderer'

export default {
  mixins: [UiMixin],
  components: {
    FiltersRenderer,
  },

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'label',
    'hasOtherFilter',
  ],
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', () => {
      this.checkWidth()
    })
  },
  methods: {
    checkWidth() {
      this.isDesktop = window.innerWidth >= this.windWidth;
    },
    showFilterModal() {
      this.$modal.show('filters-modal');
      this.$body.classList.add('is-active-filters-modal');
    },
    hideFilterModal() {
      this.$modal.hide('filters-modal');
      this.$body.classList.remove('is-active-filters-modal');
    },
  },
}
</script>
