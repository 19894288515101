import CheckboxWidget from '../Widgets/CheckboxWidget'
import OtherFilterWidget from '../Widgets/OtherFilterWidget'
import CheckboxWidgetSingle from '../Widgets/CheckboxWidgetSingle'
import RangeWidget from '../Widgets/RangeWidget'
import RadioWidget from '../Widgets/RadioWidget'
import SelectWidget from '../Widgets/SelectWidget'
import MultiselectWidget from '../Widgets/MultiselectWidget'
import AutocompleteMultiselectWidget from '../Widgets/AutocompleteMultiselectWidget'
import ColorWidget from '../Widgets/ColorWidget'

const WIDGETS_MAP = {
  checkbox: CheckboxWidget,
  'checkbox-single': CheckboxWidgetSingle,
  range: RangeWidget,
  radio: RadioWidget,
  color: ColorWidget,
  select: SelectWidget,
  'other-filters': OtherFilterWidget,
  AutocompleteMultiselectWidget,
  MultiselectWidget,
}

export default {
  widgetsMap: WIDGETS_MAP,

  data() {
    return {
      modalAttrs: {
        name: 'filters-modal',
        width: '100%',
        height: '100%',
        class: ['modal-filters-full'],
        adaptive: true,
      },
      isDesktop: true,
      windWidth: 1200,
    }
  },

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
      }
    },
  },

  methods: {
    getOtherValue() {
      const val = []
      if (this.value.language) {
        val.push('language')
      }
      if (this.value.driver_license) {
        val.push('driver_license')
      }
      if (this.value.biometric_passport) {
        val.push('biometric_passport')
      }
      return val
    },
    updateOtherValue(otherObject) {
      const clearOther = {
        biometric_passport: undefined,
        driver_license: undefined,
        language: undefined,
      }
      this.$emit('input', { ...this.value, ...clearOther, ...otherObject })
    },
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    getMultipleValues(key) {
      if (this.value[`${key}_min`] || this.value[`${key}_max`]) {
        return [
          Number(this.value[`${key}_min`]) || null,
          Number(this.value[`${key}_max`]) || null,
        ]
      }
      return []
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values
      res[`${key}_min`] = min
      res[`${key}_max`] = max
      this.$emit('input', { ...this.value, ...res })
    },
  },
}
