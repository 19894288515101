<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initialParams"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="listResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
      @result="$emit('result', $event)"
    )
      component(
        ref="controller"
        :is="controllerName"
        :attributesResource='filtersResource'
        :additionalResource='additionalResource'
        v-bind="paginatedProps",
        :totalItems="(paginatedProps.result && paginatedProps.result.items) ? paginatedProps.result.pagination.total : 0"
        :label='label',
        :filterName="filterName"
        :needSearch="needSearch"
        :needAddBtns="needAddBtns"
        :hasOtherFilter="hasOtherFilter"
      )
        template(#heading)
          slot(name="heading")
        template(#content)
          slot(
            name="content"
            :catalogViewType="contentProps.catalogViewType"
            :total="paginatedProps.pagination.total"
          )
</template>

<script>
import Controller from '@app/List/Controller'
import ControllerCabinet from '@app/List/ControllerCabinet'
// import { friendlyUrlParser, parsePaginationUrl } from '@app/Filters/transformers'
import { queryStringParser, parsePaginationUrl } from '@app/Filters/transformers'

export default {
  components: {
    Controller,
    ControllerCabinet,
  },

  props: {
    initial: {},
    baseUrl: {},
    listResource: {},
    filtersResource: {},
    additionalResource: {},
    label: {},
    needSearch: {},
    controllerName: {},
    filterName: {},
    needAddBtns: {},
    hasOtherFilter: () => false,
  },

  data() {
    return {
      initialParams: {},
    }
  },

  created() {
    const query = parsePaginationUrl()
    this.initialParams = {
      ...this.initial,
      ...query,
    }
  },

  methods: {
    getData() {
      if (this.$refs.controller.getData) {
        this.$refs.controller.getData()
      }
    },

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },

    parametersFrom({ ...rest }) {
      // const data = friendlyUrlParser()
      const data = queryStringParser(window.location.search)
      return {
        ...rest,
        filters: data,
        label: this.label,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
