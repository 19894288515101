<template lang='pug'>
  include ../Bemto
  +b.i-filter
    filter-ui(
      ref="filterUi"
      :disabled="loading"
      :value="prepared"
      :filters="filters"
      :pagination='pagination'
      :additional-resource="additionalResource"
      :label="label"
      @input="handleInputFilter"
      :hasOtherFilter="hasOtherFilter"
    )
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
