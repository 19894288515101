<template lang="pug">
include ../../Bemto
+b.file-uploader
  +e.INPUT.hidden(
    type='file'
    :ref="'fileUploader'"
    :name="'file'"
    :id="id"
    :accept="acceptedExtensions"
    @change='uploadSource'
    :multiple="multiple"
  )
  label(
    v-if='!newFile'
    :for="id"
  )
    slot
  +e.file(
    v-if='newFile'
  )
    +b.ma--r-xs
      +b.APP-ICON(name="icon-file")
    +e.P.filename {{ newFile.name }}
    +e.cross
      +b.APP-ICON.text.--pointer-green(
        name="icon-trash"
        @click='removeHandler'
      )
</template>

<script>
export default {
  props: {
    name: {},
    setValue: {},
    acceptedExtensions: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  name: 'file-uploader',
  data() {
    return {
      id: Date.now(),
      file: null,
    }
  },
  computed: {
    newFile() {
      if (this.setValue) {
        /* eslint-disable vue/no-side-effects-in-computed-properties */
        this.file = {
          name: this.setValue.name,
          file: this.setValue.path,
        }
      }
      return this.file
    },
  },
  methods: {
    uploadSource(event) {
      const { ...files } = event.target.files
      Object.entries(files).forEach(file => {
        if (5242880 > file[1].size) {
          const reader = new FileReader()
          reader.readAsDataURL(file[1])
          reader.onload = () => {
            this.prefillSrc(file[1].name, reader.result)
          }
          // this.$nextTick(() => {
          //   this.$refs.fileUploader.value = null
          // })
        } else {
          this.$notify(
            {
              title: this._('Error'),
              text: this._('Убедитесь, что размер этого файла не превышает 5Мб'),
              position: 'top middle',
              type: 'error',
            }
          )
        }
      })
    },
    prefillSrc(name, data) {
      this.file = {
        file: data,
        name,
      }
      this.$emit('file-data', this.file || null)
    },
    removeHandler() {
      this.file = null
      this.$emit('file-data', this.file)
    },
  },
}
</script>
