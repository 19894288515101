import {
  createResource,
  deleteResource,
  postResource,
  patchResource,
  createFileSender,
  // putResource,
  // optionsResource,
} from '../resources/resource'

import {
  simpleTransformer,
} from '../resources/transformer'

import { ENDPOINTS } from '../ENDPOINTS'

export const feedbackCreateAPI = createResource(
  ENDPOINTS.FEEDBACK,
  postResource
)
export const currentUserAPI = createResource(
  ENDPOINTS.CURRENT_USER
)
export const authorizationAPI = createResource(
  ENDPOINTS.AUTH,
  postResource
)
export const verificationAPI = createResource(
  ENDPOINTS.VERIFICATION,
  postResource
)
export const changePasswordAPI = createResource(
  ENDPOINTS.CHANGE_PASSWORD,
  patchResource
)
export const checkPasswordAPI = createResource(
  ENDPOINTS.CHECK_PASSWORD,
  postResource
)
export const resetPasswordAPI = createResource(
  ENDPOINTS.RESET_PASSWORD,
  postResource
)
export const resetPasswordConfirmAPI = createResource(
  ENDPOINTS.RESET_PASSWORD_CONFIRM,
  postResource
)
export const filtersAPI = createResource(
  ENDPOINTS.FILTERS
)
export const registerTalantAPI = createResource(
  ENDPOINTS.CREATE_TALANT,
  postResource
)
export const signInAPI = createResource(
  ENDPOINTS.LOGIN,
  postResource
)
export const generalInfoAPI = createResource(
  ENDPOINTS.GENERAL_INFO,
  postResource
)
export const visualInfoAPI = createResource(
  ENDPOINTS.VISUAL_DATA,
  postResource
)
export const bodyInfoAPI = createResource(
  ENDPOINTS.BODY_DATA,
  postResource
)
export const clothesInfoAPI = createResource(
  ENDPOINTS.CLOTHES_DATA,
  postResource
)
export const mediaDataAPI = createResource(
  ENDPOINTS.MEDIA_DATA,
  postResource
)
export const addToFavoriteApi = createResource(
  ENDPOINTS.ADD_TO_FAVORITE,
  postResource
)
export const favoriteListApi = createResource(
  ENDPOINTS.FAVORITE_LIST
)
export const favoriteIdListApi = createResource(
  ENDPOINTS.FAVORITE_ID_LIST
)
export const addFavoriteListApi = createResource(
  ENDPOINTS.FAVORITE_LIST,
  postResource
)
export const talentsApi = createResource(
  ENDPOINTS.TALENTS
)
export const characteristicsAPI = createResource(
  ENDPOINTS.CHARACTERISTICS
)
export const projectSelectsAPI = createResource(
  ENDPOINTS.PROJECT_SELECTS
)
export const countriesListAPI = createResource(
  ENDPOINTS.COUNTRIES_LIST
)
export const createProjectAPI = createResource(
  ENDPOINTS.CREATE_PROJECT,
  postResource
)
export const getProjectListAPI = createResource(
  ENDPOINTS.PROJECTS_LIST
)
export const getProjectsPreviewListAPI = createResource(
  ENDPOINTS.PROJECTS_PREVIEW
)
export const addTalentsToProjectAPI = createResource(
  ENDPOINTS.ADD_TALENTS_TO_PROJECT,
  patchResource
)
export const removeTalentsFromProjectApi = createResource(
  ENDPOINTS.REMOVE_TALENTS_FROM_PROJECT,
  patchResource
)
export const getProjectAPI = createResource(
  ENDPOINTS.GET_PROJECT
)
export const patchProjectAPI = createResource(
  ENDPOINTS.UPDATE_PROJECT,
  patchResource
)
export const deleteProjectAPI = createResource(
  ENDPOINTS.REMOVE_PROJECT,
  deleteResource,
  simpleTransformer
)
export const deleteExactDates = createResource(
  ENDPOINTS.PROJECT_REMOVE_EXACT_DATES,
  deleteResource,
  simpleTransformer
)
export const deleteReferenceFiles = createResource(
  ENDPOINTS.PROJECT_REMOVE_REFERENCE_FILES,
  deleteResource,
  simpleTransformer
)
export const deleteReferenceLinks = createResource(
  ENDPOINTS.PROJECT_REMOVE_REFERENCE_LINKS,
  deleteResource,
  simpleTransformer
)
export const botRequestAPI = createResource(
  ENDPOINTS.BOT_REQUEST,
  postResource
)

export const talentIdAPI = createResource(
  ENDPOINTS.GET_TALENT_ID
)
export const patchAccountAPI = createResource(
  ENDPOINTS.PATCH_ACCOUNT,
  patchResource
)
export const addToProjectFromFavoriteAPI = createResource(
  ENDPOINTS.ADD_TALENTS_FROM_FAVORITE_TO_PROJECT,
  postResource
)
export const removeFavoriteTalentsAPI = createResource(
  ENDPOINTS.REMOVE_TALENTS_FROM_FAVORITE,
  deleteResource,
  simpleTransformer
)
export const removeGalleryPhotoAPI = createResource(
  ENDPOINTS.REMOVE_GALLERY_PHOTO,
  deleteResource,
  simpleTransformer
)
export const removeAccountApi = createResource(
  ENDPOINTS.REMOVE_ACCOUNT,
  deleteResource,
  simpleTransformer
)
export const removeReferenceAPI = createResource(
  ENDPOINTS.REMOVE_REFERENCE,
  deleteResource,
  simpleTransformer
)

export const deleteUploadedImageAPI = createResource(
  ENDPOINTS.ACCOUNTS_ACTIONS_DELETE_UPLOADED_IMAGE,
  deleteResource,
  simpleTransformer
)
export const deleteUploadedVideoAPI = createResource(
  ENDPOINTS.ADMIN_ACTIONS_DELETE_UPLOADED_VIDEO,
  deleteResource,
  simpleTransformer
)
export const deleteImageAPI = createResource(
  ENDPOINTS.ADMIN_ACTIONS_DELETE_PHOTO,
  deleteResource,
  simpleTransformer
)
export const deleteVideoAPI = createResource(
  ENDPOINTS.ADMIN_ACTIONS_DELETE_VIDEO,
  deleteResource,
  simpleTransformer
)
export const uploadImageAPI = createFileSender(
  ENDPOINTS.ACCOUNTS_ACTIONS_UPLOAD
)
export const uploadVideoAPI = createFileSender(
  ENDPOINTS.ADMIN_ACTIONS_UPLOAD_VIDEO
)

export const talentsCountAPI = createResource(
  ENDPOINTS.ADMIN_TALENTS_COUNT
)
export const talentsListAPI = createResource(
  ENDPOINTS.ADMIN_TALENTS_LIST
)
export const talentUpdateAPI = createResource(
  ENDPOINTS.ADMIN_TALENTS_UPDATE,
  patchResource
)
export const adminTalentIdAPI = createResource(
  ENDPOINTS.ADMIN_TALENTS_RETRIEVE
)
