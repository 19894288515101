<template lang="pug">
include ../../Bemto
+b.P Client main component
</template>

<script>
export default {
  name: 'cabinet-client',
  data() {
    return {

    }
  },
}
</script>
