<template lang="pug">
  include ../../Bemto
  div
    //- +b.ds-panel.--space_lg(v-if="choicesLocal.length > 20")
    //-   +e.element.--offset_bottom
    //-     d-control-static-input(
    //-       v-model='searchValue',
    //-       @input="searchAttrs"
    //-       type="text"
    //-       :placeholder="_('Поиск')"
    //-     )
    div(
      v-if='filter.options[0] && filter.options[0].color'
    )
      +b.checkbox-block(
        v-for="(item, index) in filter.options"
        :style="{marginBottom: '15px'}"
      )
        +e.INPUT.hidden-checkbox(
          type='checkbox'
          :name='filter.name'
          :id='filter.name + index'
          v-model='checked'
          :value='item.value'
          @change='submit'
        )
        +e.wrapper
          +b-context('checkbox-block')
            +e.LABEL.fake-checkbox-color(
              :for='filter.name + index'
              :style="{border: `1px solid ${item.color}`}"
            )
              div.color-checkbox-item(
                v-if='checked.includes(item.value)'
                :style="{backgroundColor: `${item.color}`}"
              )
        +b.LABEL.text.--size-sm.--pointer(
          :class="{'text--weight-bold': checked.includes(item.value)}"
          :for='filter.name + index'
        )
          +b.SPAN {{ item.displayName }}
          +b.SPAN.text.--color-gray.--weight-default.ma--l-2xxs {{ `(${item.countTalents})` }}
    div(
      v-else
    )
      +b.checkbox-block(
        v-for="(item, index) in filter.options"
        :style="{marginBottom: '15px'}"
      )
        +e.INPUT.hidden-checkbox(
          type='checkbox'
          :name='filter.name'
          :id='filter.name + index'
          v-model='checked'
          :value='item.value'
          @change='submit'
        )
        +e.wrapper
          +b-context('checkbox-block')
            +e.LABEL.fake-checkbox(
              :for='filter.name + index'
            )
        +b.LABEL.text.--size-sm.--pointer(
          :class="{'text--weight-bold': checked.includes(item.value)}"
          :for='filter.name + index'
        )
          +b.SPAN {{ item.displayName }}
          +b.SPAN.text.--color-gray.--weight-default.ma--l-2xxs {{ `(${item.countTalents})` }}
</template>
<script>
export default {
  name: 'CheckboxWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = this.normalize(nval)
      },
    },

    // filter: {
    //   deep: true,
    //   immediate: true,
    //   handler(nval) {
    //     if (nval && nval.values) {
    //       this.choicesLocal = nval.values
    //     }
    //   },
    // },
  },

  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$emit('input', this.checked)
    },

    normalize(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.options
        .filter(el => val.find(v => Number(v) === el.value)).map(el => el.value)
      return checked
    },

    // searchAttrs() {
    //   this.$nextTick(() => {
    //     const choices = JSON.parse(JSON.stringify(this.filter.values))
    //     this.choicesLocal = choices.filter(choice => {
    //       const searchVal = this.searchValue.toLowerCase()
    //       const choiceName = choice.values[0].toLowerCase()
    //       if (choiceName.includes(searchVal)) {
    //         return choice
    //       }
    //       return false
    //     })
    //   })
    // },
  },
}
</script>
