const CheckMark = () => import('./CheckMark.vue');
const WhatsApp = () => import('./WhatsApp.vue');
const Telegram = () => import('./Telegram.vue');
const Facebook = () => import('./Facebook.vue');
const Instagram = () => import('./Instagram.vue');
const Aim = () => import('./Aim.vue');
const Mail = () => import('./Mail.vue');
const Arrow = () => import('./Arrow.vue');
const Calendar = () => import('./Calendar.vue');
const FacebookSquare = () => import('./FacebookSquare.vue');
const EyeOn = () => import('./EyeOn.vue');
const EyeOff = () => import('./EyeOff.vue');
const Cross = () => import('./Cross.vue');
const Tiktok = () => import('./Tiktok.vue');
const User = () => import('./User.vue');
const UserOutline = () => import('./UserOutline.vue');
const Heart = () => import('./Heart.vue');
const HeartFull = () => import('./HeartFull.vue');
const Star = () => import('./Star.vue');
const StarFull = () => import('./StarFull.vue');
const Sideleft = () => import('./Sideleft.vue');
const Search = () => import('./Search.vue');
const Point = () => import('./Point.vue');
const Phone = () => import('./Phone.vue');
const TriangleDown = () => import('./TriangleDown.vue');
const TriangleUp = () => import('./TriangleUp.vue');
const Pen = () => import('./Pen.vue');
const Trash = () => import('./Trash.vue');
const Clock = () => import('./Clock.vue');
const Plus = () => import('./Plus.vue');
const File = () => import('./File.vue');
const ArrowLeft = () => import('./ArrowLeft.vue');
const Message = () => import('./Message.vue');
const Refresh = () => import('./Refresh.vue');
const Menu = () => import('./Menu.vue');
const Shape = () => import('./Shape.vue');
const Logout = () => import('./Logout.vue');
const CheckmarkThin = () => import('./CheckmarkThin.vue');
const MagnifyPlusOutline = () => import('./MagnifyPlusOutline.vue')
const UserCircle = () => import('./UserCircle.vue');
const Home = () => import('./Home.vue');
const Settings = () => import('./Settings.vue');
const Filter = () => import('./Filter.vue');
const MenuStar = () => import('./MenuStar.vue');
const Info = () => import('./Info.vue');
const ChevronDown = () => import('./ChevronDown.vue');

const DEFAULT_COMPONENT_PREFIX = 'Icon';
export default function install(
  Vue,
  { prefix = DEFAULT_COMPONENT_PREFIX } = {}
) {
  Vue.component(`${prefix}CheckMark`, CheckMark);
  Vue.component(`${prefix}WhatsApp`, WhatsApp);
  Vue.component(`${prefix}Telegram`, Telegram);
  Vue.component(`${prefix}Facebook`, Facebook);
  Vue.component(`${prefix}Instagram`, Instagram);
  Vue.component(`${prefix}Aim`, Aim);
  Vue.component(`${prefix}Mail`, Mail);
  Vue.component(`${prefix}Arrow`, Arrow);
  Vue.component(`${prefix}Calendar`, Calendar);
  Vue.component(`${prefix}FacebookSquare`, FacebookSquare);
  Vue.component(`${prefix}EyeOn`, EyeOn);
  Vue.component(`${prefix}EyeOff`, EyeOff);
  Vue.component(`${prefix}Cross`, Cross);
  Vue.component(`${prefix}Tiktok`, Tiktok);
  Vue.component(`${prefix}User`, User);
  Vue.component(`${prefix}UserOutline`, UserOutline);
  Vue.component(`${prefix}Heart`, Heart);
  Vue.component(`${prefix}HeartFull`, HeartFull);
  Vue.component(`${prefix}Star`, Star);
  Vue.component(`${prefix}StarFull`, StarFull);
  Vue.component(`${prefix}Sideleft`, Sideleft);
  Vue.component(`${prefix}Search`, Search);
  Vue.component(`${prefix}Point`, Point);
  Vue.component(`${prefix}Phone`, Phone);
  Vue.component(`${prefix}TriangleDown`, TriangleDown);
  Vue.component(`${prefix}TriangleUp`, TriangleUp);
  Vue.component(`${prefix}Pen`, Pen);
  Vue.component(`${prefix}Trash`, Trash);
  Vue.component(`${prefix}Clock`, Clock);
  Vue.component(`${prefix}Plus`, Plus);
  Vue.component(`${prefix}File`, File);
  Vue.component(`${prefix}ArrowLeft`, ArrowLeft);
  Vue.component(`${prefix}Message`, Message);
  Vue.component(`${prefix}Refresh`, Refresh);
  Vue.component(`${prefix}Menu`, Menu);
  Vue.component(`${prefix}Shape`, Shape);
  Vue.component(`${prefix}Logout`, Logout);
  Vue.component(`${prefix}CheckmarkThin`, CheckmarkThin);
  Vue.component(`${prefix}MagnifyPlusOutline`, MagnifyPlusOutline);
  Vue.component(`${prefix}UserCircle`, UserCircle);
  Vue.component(`${prefix}Home`, Home);
  Vue.component(`${prefix}Settings`, Settings);
  Vue.component(`${prefix}Filter`, Filter);
  Vue.component(`${prefix}MenuStar`, MenuStar);
  Vue.component(`${prefix}Info`, Info);
  Vue.component(`${prefix}ChevronDown`, ChevronDown);
}
