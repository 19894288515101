<template lang="pug">
include ../Bemto
+b.verification-wrapp: LoadingWrapper(:loading="isLoad"): +e.wrap
  +e.P.title.text.--align-center.--weight-bold.--size-xl.--font-serif {{ _("Password reset") }}
  +b.P.text.--align-center.--size-sm {{ _('Choose security password. We recommend using capital letters and special characters.') }}
  +e.VALIDATION-OBSERVER.form(
    :style="{marginTop: '24px'}"
    ref='validator'
    v-slot="{ valid }"
    tag='form'
  )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|min:8'"
      v-model='form.password'
      type='password'
      name='password'
      :placeholder="_('Password')"
    )
    d-control-input(
      :style="{marginTop: '-25px'}"
      :class="'register-input'"
      :rules="'required|min:8|confirmed:password'"
      v-model='form.password2'
      type='password'
      name='password2'
      :placeholder="_('Repeat password')"
    )
    +b.BUTTON.el-btn.--default.text.--space-lg.--color-white.--transform-upper.--weight-bold(
      @click.prevent="prepareData(valid)"
    ) {{ _('Reset password') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { resetPasswordConfirmAPI } from '@requests/services/services'

export default {
  mixins: [
    FormMixin,
  ],
  name: 'password-reset',
  props: {
    code: {},
    token: {},
  },
  data() {
    return {
      form: {
        password: null,
        password2: null,
        code: this.code,
        token: this.token,
      },
    }
  },
  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
    async prepareData(valid) {
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(valid, this.form);
    },
    send(data) {
      return resetPasswordConfirmAPI.execute({}, data).then(() => {
        window.location.pathname = '/auth/login/'
      })
    },
  },
}
</script>
