<template lang="pug">
include ../../Bemto
router-view
</template>

<script>
export default {
  name: 'cabinet-talant',
}
</script>
