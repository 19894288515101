<template lang="pug">
  include ../../Bemto
  div
    +b.checkbox-block(
      v-for="(item, index) in options"
      :style="{marginBottom: '15px'}"
    )
      +e.INPUT.hidden-checkbox(
        type='checkbox'
        :name="'other'"
        :id="'other_' + index"
        v-model='checked'
        :value='item.value'
        @change='submit'
        :checked="checked.includes(item.value)"
      )
      +e.wrapper
        +b-context('checkbox-block')
          +e.LABEL.fake-checkbox(
            :for="'other_' + index"
          )
      +b.LABEL.text.--size-sm.--pointer(
        :class="{'text--weight-bold': checked.includes(item.value)}"
        :for="'other_' + index"
      )
        +b.SPAN {{ item.displayName }}
</template>
<script>
export default {
  name: 'OtherFilterWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
      options: [
        {
          value: 'biometric_passport',
          displayName: this._('Закородонний паспорт (біометрія)'),
        },
        {
          value: 'driver_license',
          displayName: this._('Права водія'),
        },
        {
          value: 'language',
          displayName: this._('Іноземна мова '),
        },
      ],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = nval
      },
    },

    // filter: {
    //   deep: true,
    //   immediate: true,
    //   handler(nval) {
    //     if (nval && nval.values) {
    //       this.choicesLocal = nval.values
    //     }
    //   },
    // },
  },

  mounted() {
    this.checked = this.value
  },

  methods: {
    submit() {
      this.$emit('input', this.prepare(this.checked))
    },
    prepare(checked) {
      return checked.reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {})
    },

    normalize(value) {
      let val = value
      if (!value) return []
      if (!Array.isArray(value)) {
        val = [value]
      }
      const checked = this.filter.options
        .filter(el => val.find(v => Number(v) === el.value)).map(el => el.value)
      return checked
    },

    // searchAttrs() {
    //   this.$nextTick(() => {
    //     const choices = JSON.parse(JSON.stringify(this.filter.values))
    //     this.choicesLocal = choices.filter(choice => {
    //       const searchVal = this.searchValue.toLowerCase()
    //       const choiceName = choice.values[0].toLowerCase()
    //       if (choiceName.includes(searchVal)) {
    //         return choice
    //       }
    //       return false
    //     })
    //   })
    // },
  },
}
</script>
