<template lang="pug">
  include ../../Bemto
  div
    row.filer-range-block(
      :justify="'between'"
      :appearance="'spaced'"
      :align="'center'"
    )
      cell
        row(
          :align="'center'"
        )
          cell
            +b.range-label
              +b.P.text.--size-sm {{ _('from') }}
          cell
            +b.INPUT.filter-range-data(
              type='number'
              v-model='internal[0]'
              @input='update'
            )
          cell(
            v-if="filter.name === 'height'"
          )
            +b.P.text.--size-sm {{ _('см') }}
      cell
        row(
          :align="'center'"
        )
          cell
            +b.range-label
              +b.P.text.--size-sm {{ _('to') }}
          cell
            +b.INPUT.filter-range-data(
              type='number'
              v-model='internal[1]'
              @input='update'
            )
          cell(
            v-if="filter.name === 'height'"
          )
            +b.P.text.--size-sm {{ _('см') }}
    range-slider.filter-range(
      v-model="internal"
      :tooltip="'none'"
      :clickable="false"
      :drag-on-click="true"
      transition="0.2"
      :dot-size="19"
      :height="3"
      :min="min"
      :max="max"
      :interval="interval"
      @drag-end="update"
    )
</template>

<script>
/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === b ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

export default {
  name: 'filter-slider-widget',
  props: ['value', 'filter'],

  data() {
    return {
      internal: [0, 1],
      interval: 1,
      min: this.filter.options.min,
      max: this.filter.options.max,
    }
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    value: { deep: true ,immediate: true, handler: 'updateInternal' },
  },

  methods: {
    clearMinMax() {
      const min = this.filter.options.min
      const max = this.filter.options.max
      this.min = parseFloat(Math.max(min || 0).toFixed(2))
      this.max = parseFloat(Math.min(max || 0).toFixed(2))
      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)
    },

    update() { 
      const value = this.clear(this.internal)
      this.input(value)
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      let [min, max] = value
      const [minVal, maxVal] = this.normalize([min, max])
      min = minVal
      max = maxVal
      if (min === this.min && max === this.max) {
        return []
      }

      return [min, max]
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const [min, max] = value

      return [min || this.min, max || this.max]
    },
  },
};

</script>
