<template lang="pug">
include ../Bemto
div(
  :style="{width: '100%', maxWidth: '1110px', margin: '0 auto'}"
)
  +b.g-row--space_sm.--justify_between.--align_center.ma--b-sm
    +b.g-cell.g-cols
      +b.P.text.go-prev--clear.text.--color-black(
        @click='goBack(talentsCatalogUrl)'
      ) {{ _('Back') }}
    +b.g-cell.g-cols(
      v-if="isAdmin && !isVerified"
    )
      +b.BUTTON.moderate-talent-profile(
        type="button"
        @click="moderate"
      )
        +e.P.text {{ _("Модерувати") }}
  +b.panel.--bg-white.--br-default.pa--round-xs.pa--round-lg-md
    +b.talent-detail-wrapper
      +e.photo-block
        +b.talent-photo-view(
          ref="swipe"
        )
          light-box(
            :source="photos"
          )
            template(v-slot:default='{ show }')
              +b.VIDEO.talent-photo-view__photo--video(
                v-if="activePhoto.videoFile"
                playsinline=''
                controls='controls'
                controlslist='nodownload'
                :src='activePhoto.videoFile'
                autoplay
                muted
                width="100%"
                height="100%"
              )
                source(:src='activePhoto.videoFile' type='video/mp4')
                | {{ _("Sorry, your browser doesn't support embedded videos") }}
              +b.IMG.talent-photo-view__photo(
                v-else
                :src='activePhoto'
                @click='show(currentPhoto)'
              )
              +b.talent-photo-view__zoom-icon
                +b.APP-ICON(
                  name="icon-magnify-plus-outline"
                  size="xl"
                  @click='show(currentPhoto)'
                )
          //- +b.scale-btn
          //-   +b.P.icon-search
        +b.pseudo-slider
          +e.left(
            @click='prevPhoto(currentPhoto)'
          )
          +e.photo-block(
            ref='photoBlock'
          )
            +e.item(
              :class="[currentPhoto != index ? 'blur' : '']"
              v-for="(item, index) in combinedPhotos"
              :key='index'
              @click='switchSlide(item, index)'
            )
              .pseudo-slider__image.dummy-video-preview(
                v-if="item.videoFile"
              )
              picture(v-else)
                source(
                  v-if="item.imageLinks && item.imageLinks.previewWebp"
                  :srcset="item.imageLinks.previewWebp || 'https://via.placeholder.com/400'" type='image/webp'
                )
                img.pseudo-slider__image(
                  :src="item.imageLinks && item.imageLinks.previewBase || 'https://via.placeholder.com/400'"
                  alt='avatar'
                )
          +e.right(
            @click='nextPhoto(currentPhoto)'
          )
        +b.panel.--bg-sky-blue.--br-default.pa--t-sm.pa--b-sm.pa--l-lg.pa--r-lg.ma--t-lg.contact--desktop
          +b.P.text.--weight-bold.--size-lg.--font-serif {{ _('Contacts') }}
          row.ma--t-2sm(
            :align="'center'"
          )
            cell(
              :cols="['12', '6-sm']"
            )
              row(appearance="nowrap")
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-point")
                cell
                  +b.P {{ talent.city }}
              row(appearance="nowrap").ma--t-sm
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-phone")
                cell
                  COMPONENT(
                    :is="isHidden ? 'a' : 'p'"
                    :class="{ 'ds-link': isHidden }"
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  ) {{ talent.phone }}
            cell(
              :cols="['12', '6-sm']"
            )
              row(
                :justify="'end'"
              )
                cell(
                  v-if="isHidden || talent.facebookLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.facebookLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--facebook.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(
                        name="icon-facebook-square"
                      )
                cell(
                  v-if="isHidden || talent.instagramLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.instagramLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--insta.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(name="icon-instagram")
                cell(
                  v-if="isHidden || talent.telegramLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.telegramLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--telegram.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(name="icon-telegram")
                cell(
                  v-if="isHidden || talent.tiktokLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.tiktokLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--tiktok.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(
                        name="icon-tiktok"
                      )
      +e.data-block
        +b.talent-data(
          :style="{paddingBottom: '10px'}"
        )
          +e.name(
            v-if='characteristic'
          )
            row(justify="between", align="start" appearance="nowrap")
              cell(:cols="[]")
                +b.H1.text.--font-serif.--weight-bold {{ talent.firstName }}
              cell(
                v-if="userId && userId == talent.id"
                cols="narrow"
              )
                +b.A.edit-talent-profile(
                  href="/cabinet/profile"
                )
                  +b.ds-inliner--size_sm
                    +e.ICON-PEN.body
                  +e.text {{ _("Редагувати") }}
              cell(
                v-if="isAdmin"
                cols="narrow"
              )
                +b.A.edit-talent-profile(
                  :href="`/cabinet/talents/${talent.id}/edit`"
                )
                  +b.ds-inliner--size_sm
                    +e.ICON-PEN.body
                  +e.text {{ _("Редагувати") }}
            +b.SPAN.id-label {{ 'ID: ' + talent.id + ' ' }}
            span(
              v-if="characteristic && Object.keys(characteristic).includes('skills')"
            )
              +b.SPAN.skill-label(
                v-for='item, idx in talent.skills'
                :key='idx'
              ) {{ singleChar('skills', item) + (talent.skills.length > idx+1 ? ', ' : ' ') }}
        +b.talent-data(v-if="isAdmin")
          hr
          row.ma--t-2xs.ma--b-2xs(
            align="center"
          )
            cell(
              :cols="['5']"
            )
              v-popover(
                :popover-class="'tooltip-modal'"
                :delay="{ show: 500, hide: 500 }"
                :key='talent.id'
              )
                +b.P.bar-label--proj.text.--size-sm.--pointer-green.--weight-bold(
                ) {{ _('To projects') }}
                template(#popover)
                  project-bar.talent-projects-bar(
                    :id='talent.id'
                    :key='talent.id'
                  )
            cell(
              :cols="['5']"
            )
              favorites-bar(
                :id='talent.id'
                :key='talent.id'
                v-slot="{ isFavorite, toggle }"
              )
                +b.g-row--align_center.--space_sm.card-favorites-bar(
                  :class="{ 'is-active': isFavorite }"
                )
                  +b.g-cell.g-cols
                    +b.APP-ICON.text.--pointer-green(
                      size="xl"
                      name="icon-heart"
                      @click.prevent="toggle()"
                    )
                  +b.g-cell.g-cols
                    +b.P.text.--size-sm.--pointer-green.--weight-bold(
                      :class="isFavorite ? 'text--color-green' : ''"
                      @click.prevent="toggle()"
                    ) {{ _('To favorites') }}
        hr
        row(
          v-if='characteristic'
          space="sm"
          appearance="spaced"
          align="center"
        )
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Gender') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('genders')"
            ) {{ singleChar('genders', talent.gender) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Age') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.age }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Hairs') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('hairColors')"
            ) {{ singleChar('hairColors', talent.hairColor) + ', ' + singleChar('hairTypes', talent.hairType).toLowerCase() }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Eyes color') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('eyesColors')"
            ) {{ singleChar('eyesColors', talent.eyesColor) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Skin color') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('skinColors')"
            ) {{ singleChar('skinColors', talent.skinColor) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Body type') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('bodyTypes')"
            ) {{ singleChar('bodyTypes', talent.bodyType) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Height') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.height }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Weight') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.weight }}
          //- cell(
          //-   :cols="['12']"
          //- )
            +b.P.text.--weight-bold {{ _('Type') }}
            row.card-data
              cell(
                v-for='tag in talent.types'
                :key='tag'
              )
                +b.card-tag.text.--size-xs(
                  v-if="characteristic && Object.keys(characteristic).includes('talentTypes')"
                ) {{ singleChar('talentTypes', tag) }}
          cell(cols="12")
            hr
          cell.ma--t-xxs.ma--b-xxs(
            :cols="['12']"
          )
            +b.H2.text.--weight-bold.--size-lg {{ _('Clothes') }}
          cell(
            :cols="['12']"
          )
            row
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Top') }}
                +b.P.text(
                  v-if="characteristic && Object.keys(characteristic).includes('clothingSizes')"
                ) {{ singleChar('clothingSizes', talent.clothingSizeTop) }}
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Bottom') }}
                  +b.P.text(
                    v-if="characteristic && Object.keys(characteristic).includes('clothingSizes')"
                  ) {{ singleChar('clothingSizes', talent.clothingSizeBottom) }}
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Shoe') }}
                +b.P.text {{ talent.shoeSize }}
          cell(
            v-if="talent.specials && talent.specials.length"
            :cols="['12']"
          )
            +b.P.text.--weight-bold.--size-lg {{ _('Special') }}
            row.card-data(
              appearance="spaced"
              space="xs"
            )
              cell(
                v-for='tag in talent.specials'
                :key='tag'
              )
                +b.card-tag.text.--size-xs(
                  v-if="characteristic && Object.keys(characteristic).includes('specials')"
                ) {{ singleChar('specials', tag) }}
          cell(cols="12")
            hr
          cell(cols="12")
            +b.P.text.--weight-bold.--size-lg {{ _('Інше') }}

          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Закордонний паспорт') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.icon.icon-check-mark.text--color-green(v-if="talent.biometricPassport")
            +b.P.text(v-else) {{ _('Не маю') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Права водія') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.icon.icon-check-mark.text--color-green(v-if="talent.driverLicense")
            +b.P.text(v-else) {{ _("Не маю") }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Іноземна мова') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ knowledgeOfLanguage }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Досвід фотозйомки') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ singleChar('professionalPhotoExperience', talent.professionalPhotoExperience) }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Досвід відеозйомки') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ singleChar('professionalVideoExperience', talent.professionalVideoExperience) }}
          //- cell.ma--t-lg(
            :cols="'12'"
          //- )
            hr
            row.ma--t-2xs.ma--b-2xs
              cell(
                :cols="['5']"
              )
                v-popover(
                  :popover-class="'tooltip-modal'"
                  :delay="{ show: 500, hide: 500 }"
                  :key='talent.id'
                )
                  +b.P.bar-label--proj.text.--size-sm.--pointer-green.--weight-bold(
                  ) {{ _('To projects') }}
                  template(#popover)
                    project-bar.talent-projects-bar(
                      :id='talent.id'
                      :key='talent.id'
                    )
              cell(
                :cols="['5']"
              )
                favorites-bar(
                  :id='talent.id'
                  :key='talent.id'
                  v-slot="{ isFavorite, toggle }"
                )
                  +b.g-row--align_center.--space_sm.card-favorites-bar(
                    :class="{ 'is-active': isFavorite }"
                  )
                    +b.g-cell.g-cols
                      +b.APP-ICON.text.--pointer-green(
                        size="xl"
                        name="icon-heart"
                        @click.prevent="toggle()"
                      )
                    +b.g-cell.g-cols
                      +b.P.text.--size-sm.--pointer-green.--weight-bold(
                        :class="isFavorite ? 'text--color-green' : ''"
                        @click.prevent="toggle()"
                      ) {{ _('To favorites') }}
            hr
        +b.panel.--bg-sky-blue.--br-default.ma--t-sm.pa--round-lg.contact--mobile
          +b.P.text.--weight-bold.--size-lg.--font-serif {{ _('Contacts') }}
          row.ma--t-2lg(
            :align="'center'"
          )
            cell(
              :cols="['12', '6-sm']"
            )
              row(appearance="nowrap")
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-point")
                cell(cols="auto")
                  +b.P.text.--size-sm {{ talent.city }}
              row(
                :style="{marginTop: '20px'}"
              )
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-phone")
                cell(cols="auto")
                  +b.COMPONENT.text.--size-sm(
                    :is="isHidden ? 'a' : 'p'"
                    :class="{ 'ds-link': isHidden }"
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  ) {{ talent.phone }}
            cell(
              :cols="['12', '6-sm']"
            )
              row.ma--t-sm.ma--t-none-sm(
                :justify="['end-sm']"
              )
                cell(
                  v-if="isHidden || talent.facebookLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.facebookLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--facebook.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(name="icon-facebook")
                cell(
                  v-if="isHidden || talent.instagramLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.instagramLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--insta.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(name="icon-instagram")
                cell(
                  v-if="isHidden || talent.telegramLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.telegramLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--telegram.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(name="icon-telegram")
                cell(
                  v-if="isHidden || talent.tiktokLink != ''"
                )
                  +b.A.ds-link(
                    target='_blank'
                    :href='talent.tiktokLink'
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  )
                    +b.social-square.--tiktok.--lg
                      +b.APP-ICON.text.--color-white.--size-sm(
                        name="icon-tiktok"
                      )
  +b.talent-data-social(
    v-if='talent.videoPresentation || talent.abilityVideo'
  )
    +b.P.text.--font-serif.--weight-bold.--size-lg {{ _('More videos') }}
    row.ma--t-sm
      cell(
        :cols="['12', '6-md']"
        v-if='talent.videoPresentation'
      )
        +b.P.text.--size-sm.--weight-bold {{ _('About yourself') }}
        +b.video-item
          ui-video-view(
            :url="talent.videoPresentation"
            v-slot="{ embedUrl }"
          )
            iframe(
              :src='embedUrl'
              frameborder="0"
              allowfullscreen
            )
      cell.ma--t-sm.ma--t-none-md(
        :cols="['12', '6-md']"
        v-if='talent.abilityVideo'
      )
        +b.P.text.--size-sm.--weight-bold {{ _('Ability video') }}
        +b.video-item
          ui-video-view(
            :url="talent.abilityVideo"
            v-slot="{ embedUrl }"
          )
            iframe(
              :src='embedUrl'
              frameborder="0"
              allowfullscreen
            )
  +b.panel.--bg-white.--br-default.pa--round-xs.pa--round-lg-md(
    :class="{ 'ma--t-lg': !(talent.videoPresentation || talent.abilityVideo)}"
    v-if='lastViewed.length >= 1'
  )
    +b.P.text.--size-lg.--font-serif.--weight-bold {{ _('You recently viewed') }}
    row.ma--t-lg(
      :justify="['center', 'start-xl']"
      :space="['xl-xl']"
    )
      cell(
        :cols="['narrow', 'narrow-lg', '3-xl']"
        v-for='(item, index) in lastViewed'
        :key='index'
      )
        talent-card.recently-view-card(
          :talent='item.talent'
          :recently='true'
        )
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import { ROUTES_NAMES } from '@routes'
import {
  talentUpdateAPI,
} from '@requests/services/services'
import { goBack } from '../../utils/back'

const MessageModal = () => import('@app/commons/ui-elements/MessageModal.vue')

export default {
  name: 'talent-detail',
  props: {
    isHidden: Boolean,
    userId: String,
    talentsCatalogUrl: String,
    isAdmin: Boolean,
    talent: Object,
    lastViewed: Array,
  },
  components: {
    InnerImageZoom,
  },
  provide() {
    return {
      isAdmin: this.isAdmin,
    }
  },
  data() {
    return {
      cabinetUrl: ROUTES_NAMES.TALENT_PROFILE,
      isVerified: this.talent.verified,
      currentPhoto: 0,
      activePhotoWebp: '',
      activePhoto: '',
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getCharacteristic',
      singleChar: 'getSingleCharacteristic',
    }),
    knowledgeOfLanguage() {
      if (this.talent.knowledgeOfEnglish || this.talent.knowledgeOfOtherLanguage) {
        if (this.talent.knowledgeOfEnglish && this.talent.knowledgeOfOtherLanguage) {
          // eslint-disable-next-line prefer-template
          return this._('Англійська') + ', ' + this.talent.knowledgeOfOtherLanguage
        }
        if (this.talent.knowledgeOfOtherLanguage) {
          return this.talent.knowledgeOfOtherLanguage
        }
        return this._('Англійська')
      }
      return this._('Не воллодію')
    },
    photos() {
      const photos = []
      if (this.talent.firstPreviewImage?.base) {
        photos.push(this.talent.firstPreviewImage?.base)
      }
      if (this.talent.secondPreviewImage?.base) {
        photos.push(this.talent.secondPreviewImage.base)
      }
      const selfUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => selfUploaded)
      const adminUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => !selfUploaded)
      const videos = (this.talent.videos || []).map(video => video.videoFile)
      adminUploadedPhoto.forEach(el => {
        photos.push(el.imageLinks.base)
      })
      photos.push(...(videos || []))
      selfUploadedPhoto.forEach(el => {
        photos.push(el.imageLinks.base)
      })
      return photos
    },
    combinedPhotos() {
      const comPhotos = []
      if (this.talent.firstPreviewImage?.base) {
        const avatarOne = {
          imageLinks: this.talent.firstPreviewImage,
        }
        comPhotos.push(avatarOne)
      }

      if (this.talent.secondPreviewImage?.base) {
        const avatarTwo = {
          imageLinks: this.talent.secondPreviewImage,
        }
        comPhotos.push(avatarTwo)
      }
      const selfUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => selfUploaded)
      const adminUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => !selfUploaded)
      comPhotos.push(...adminUploadedPhoto)
      comPhotos.push(...(this.talent.videos || []))
      comPhotos.push(...selfUploadedPhoto)
      return comPhotos
    },
  },

  mounted() {
    if (this.isAdmin) {
      this.favoriteListIdRequest()
      this.projectListRequest()
    }
    this.characteristicsRequest()
    this.switchSlide(this.combinedPhotos[0], 0)
    let touchstartX = 0
    let touchendX = 0

    const checkDirection = () => {
      if (touchendX < touchstartX) this.nextPhoto(this.currentPhoto)
      if (touchendX > touchstartX) this.prevPhoto(this.currentPhoto)
    }

    this.$refs.swipe.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })
    this.$refs.swipe.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      checkDirection()
    })
  },
  methods: {
    ...mapActions('favoriteList', ['favoriteListIdRequest']),

    ...mapActions('favoriteList', ['favoriteListRequest']),
    ...mapActions('unionProjectsList', ['projectListRequest']),
    ...mapActions('talentCharacteristics', ['characteristicsRequest']),
    goBack,

    moderate() {
      talentUpdateAPI.execute({ id: this.talent.id }, { verified: true })
        .then(() => {
          this.$notify(
            {
              text: this._('Статус змінено'),
              type: 'success',
            }
          )
          this.isVerified = true
        })
    },

    openSubscriptionInfo() {
      this.$modal.show(MessageModal, {
        title: this._('Доступ для этой информации доступен только пользователю с оформленной подпиской.'),
      },
      {
        adaptive: true,
      })
    },

    embedVideo(link) {
      const watch = 'watch?v='
      const replacementLink = link.replace(watch, 'embed/')
      return replacementLink
    },
    switchSlide(item, idx) {
      if (item?.videoFile) {
        this.activePhoto = item
      } else {
        this.activePhotoWebp = item?.imageLinks?.webp || 'https://via.placeholder.com/400'
        this.activePhoto = item?.imageLinks?.base || 'https://via.placeholder.com/400'
      }
      this.currentPhoto = idx
    },
    nextPhoto(idx) {
      const index = idx + 1
      if (this.combinedPhotos.length) {
        if (index === this.combinedPhotos.length) {
          this.switchSlide(this.combinedPhotos[0], 0)
          this.$refs.photoBlock.scrollLeft = 0
        } else {
          this.$refs.photoBlock.scrollLeft += 60
          this.switchSlide(this.combinedPhotos[index], index)
        }
      }
    },
    prevPhoto(idx) {
      const index = idx - 1
      if (this.combinedPhotos.length) {
        if (0 === idx) {
          this.switchSlide(this.combinedPhotos[this.combinedPhotos.length - 1], this.combinedPhotos.length - 1)
          this.$refs.photoBlock.scrollLeft = this.$refs.photoBlock.scrollWidth
        } else {
          this.$refs.photoBlock.scrollLeft -= 60
          this.switchSlide(this.combinedPhotos[index], index)
        }
      }
    },
  },
}
</script>
