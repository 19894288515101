import {
  baseResource,
} from '@requests/resources/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixAPI } from '@requests/resources/api'

const PRODUCTS_FILTERS_URL_PREFIX = prefixAPI('filters')
const PRODUCTS_FILTERS_URL_POSTFIX = ''

const PRODUCTS_LIST_URL_PREFIX = prefixAPI('talents/list/')
const PRODUCTS_LIST_URL_POSTFIX = ''

const PROJECTS_LIST_URL_PREFIX = prefixAPI('projects/list/')
const PROJECTS_LIST_URL_POSTFIX = ''

const ADMIN_TALENTS_LIST_URL_PREFIX = prefixAPI('admin/talents/list/')
const ADMIN_TALENTS_LIST_URL_POSTFIX = ''

const FAVORITES_LIST_URL_PREFIX = prefixAPI('favorites/list/')
const FAVORITES_LIST_URL_POSTFIX = ''

const order = {
  path: [
    'prefix',
    'label',
  ],
  query: [
    'query',
  ],
}

export const productsFilterResource = friendlyUrlGenerator(
  [PRODUCTS_FILTERS_URL_PREFIX, PRODUCTS_FILTERS_URL_POSTFIX], baseResource, order
)

export const productsListResource = friendlyUrlGenerator(
  [PRODUCTS_LIST_URL_PREFIX, PRODUCTS_LIST_URL_POSTFIX], baseResource, order
)

export const projectsListResource = friendlyUrlGenerator(
  [PROJECTS_LIST_URL_PREFIX, PROJECTS_LIST_URL_POSTFIX], baseResource, order
)

export const adminTalentsListResource = friendlyUrlGenerator(
  [ADMIN_TALENTS_LIST_URL_PREFIX, ADMIN_TALENTS_LIST_URL_POSTFIX], baseResource, order
)

export const favoritesListResource = friendlyUrlGenerator(
  [FAVORITES_LIST_URL_PREFIX, FAVORITES_LIST_URL_POSTFIX], baseResource, order
)
