<template lang='pug'>
include ../../Bemto
+b.g-row--appearance_spaced.--space_sm.--align_center
  //- +b.g-cell.g-cols
  //-   +b.product-variants--free
  //-     +e.el--red( @click='clear' v-if='displayedFilters && displayedFilters.length')
  //-       +b.um-link() {{ _('Clear') }}
  +b.g-cell.g-cols
    +b.P.text.--weight-bold {{ _("Talents finded:") + " " }}
      +b.SPAN.text.--weight-default {{ totalItems }}
  +b.g-cell.g-cols
    +b.BUTTON.el-btn.--fill(
      @click.prevent="$emit('skip')"
    )
      +b.P.text.--lh-sm.pa--l-xs.pa--r-xs {{ _('Skip') }}
  +b.g-cell.g-cols(
    v-for='filter in otherFilters',
    :key='filter.name'
  )
    +b.el-btn.--fill-black
      row(
        :align="'center'"
        appearance="nowrap"
      )
        cell
          span.chips-text {{  filter.displayName }}
        cell
          div(
            :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}"
            @click.prevent='remove(filter.name)'
          )
            +b.APP-ICON(name="icon-cross")
  +b.g-cell.g-cols(
    v-for='filter in displayedFilters',
    :key='filter.name'
  )
    +b.el-btn.--fill-black
      row(
        :align="'center'"
        appearance="nowrap"
      )
        cell
          tag(
            :tag='$options.renderersMap[filter.type]',
            :filter='filter',
            :value='filters[filter.name]'
          )
        cell
          div(
            :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}"
            @click.prevent='remove(filter.name)'
          )
            +b.APP-ICON(name="icon-cross")
  +b.g-cell.g-cols(
    v-for='filter in rangeFilters',
    :key='filter.name'
  )
    +b.el-btn.--fill-black
      row(
        :align="'center'"
      )
        cell
          tag(
            :tag='$options.renderersMap[filter.type]',
            :filter='filter',
            :value="getMultiValue(filter.name, filter.options)"
          )
        cell
          div(
            :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}"
            @click.prevent='removeRange(filter.name)'
          )
            +b.APP-ICON(name="icon-cross")
</template>

<script>

import { omit } from 'ramda';
import { isEmpty } from '@aspectus/vue-utils';
// import { FILTERS_ALL } from '../const';
import {
  MultiselectRenderer,
  SelectRenderer,
  SliderRenderer,
  // DateRangeRenderer,
  // CombinedInputRenderer,
  // AutocompleteMultiselectRenderer,
} from './renderer';

export default {
  props: ['all', 'filters', 'totalItems'],

  renderersMap: {
    select: SelectRenderer,
    checkbox: MultiselectRenderer,
    range: SliderRenderer,
    // 'date-range': DateRangeRenderer,
    // 'combined-input': CombinedInputRenderer,
    // 'autocomplete-multiselect': AutocompleteMultiselectRenderer,
  },
  computed: {
    rangeFilters() {
      return Object.keys(this.all || []).reduce((acc, key) => {
        if ('range' === (this.all[key] && this.all[key].type) && this.filters) {
          const label = this.all[key].name
          if (`${label}_min` in this.filters || `${label}_max` in this.filters) {
            acc.push(this.all[key])
          }
        }
        return acc
      }, [])
    },
    displayedFilters() {
      // return (this.all || [])
      //   .filter(({ props: { attribute: { props: { label } } } }) => (
      //     label in this.filters && !isEmpty(this.filters[label])))
      //   .filter(filter => filter.props.filter_widget_type in this.$options.renderersMap);
      return Object.keys(this.all).reduce((acc, k) => {
        const filterName = this.all[k].name
        if (this.filters && !isEmpty(this.filters[filterName])) {
          acc.push(this.all[k])
        }
        return acc
      }, [])
    },
    otherFilters() {
      const other = {
        biometric_passport: this._('Закородонний паспорт (біометрія)'),
        driver_license: this._('Права водія'),
        language: this._('Іноземна мова '),
      }
      return ['biometric_passport', 'driver_license', 'language'].reduce((acc, k) => {
        if (this.filters && this.filters[k]) {
          acc.push({ displayName: other[k], name: k })
        }
        return acc
      }, [])
    },
  },

  methods: {
    update(value) {
      this.$emit('update:filters', { filters: value });
    },
    // clear() {
    //   this.$emit('input', {});
    // },
    remove(name) {
      const filtersWithoutSelected = omit([name], this.filters)
      this.update(filtersWithoutSelected)
    },
    removeRange(name) {
      const filtersWithoutSelected = omit([`${name}_min`, `${name}_max`], this.filters)
      this.update(filtersWithoutSelected)
    },
    getMultiValue(key, options) {
      /* eslint-disable no-else-return */
      if (this.filters[`${key}_min`] || this.filters[`${key}_max`]) {
        return {
          min: this.filters[`${key}_min`] || options.min,
          max: this.filters[`${key}_max`] || options.max,
        };
      } else {
        return {
          min: options.min,
          max: options.max,
        };
      }
    },
    // removeCombinedInputValue(filter, value) {
    //   const current = this.filters[filter.id];
    //   const index = current.indexOf(value);

    //   this.update({ ...this.filters, [filter.id]: remove(index, 1, current) });
    // },
  },
};

</script>
