<template lang='pug'>
  include ../Bemto
  div
    +b.filter-bar-top
      +e.search
        +b.search-wrap
          input.search-input(
            v-model='name'
            @input='debounceSearch'
            :placeholder="_('Input talent name')"
          )
      +e.selected
        //- cell
        //-   +b.P.text.--weight-bold {{ _("Talents finded:") }}
        //-     +b.SPAN.text.--weight-default {{ totalItems }}
        //- cell
        //-   +b.P.el-btn.--fill(
        //-     @click.prevent='clear'
        //-   ) {{ _('Skip') }}
        cell
          search-chips(
            @skip='clear'
            :totalItems='totalItems'
            :all='allFilters'
            :filters='filters.filters'
            @update:filters="changeFilters"
          )
    +b.i-catalog
      +e.sidebar
        catalog-sidebar(
          :filters="filters"
          @update:filters="changeFilters"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :additionalResource="additionalResource"
          :label='label'
          :totalItems="totalItems"
          :filterName="filterName"
          @get:filters:list="setFiltersList"
          :hasOtherFilter="hasOtherFilter"
        )
      +e.content
        +b.i-preloader.--variant_1(:class="{'is-active': loading}")
          +e.item.--size_md
        row(
          v-if="result && result.items.length"
          :space="'sm'"
          :justify="['center', 'start-md', 'center-lg']"
        )
          cell(
            cols="6 4-lg"
            :style="{marginBottom: '30px'}"
            v-for="(talent, index) in result.items"
            :key="`${talent.id}_${index}`"
          )
            talent-card.talent-page(
              :talent='talent'
            )
        +b.i-panel(v-else-if="!loading")
          +b.H1.text.--align-center.--font-serif {{ _('No talent found') }}
        ui-limit-pagination.talent-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
</template>

<script>
import { debounce } from 'lodash'
import SortingWidget from '@app/Filters/Sorting'
import SearchChips from '@app/List/Chips'
import CatalogSidebar from './Sidebar'

export default {
  components: {
    CatalogSidebar,
    SortingWidget,
    SearchChips,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
    'hasOtherFilter',
  ],
  data() {
    return {
      name: '',
      allFilters: {},
      debounceSearch: null,
    }
  },
  created() {
    this.receive(this.parameters)
  },

  mounted() {
    const timeout = 300

    this.debounceSearch = debounce(this.search, timeout)
  },

  methods: {
    setFiltersList(filters) {
      this.allFilters = filters
    },

    getData() {
      this.receive(this.parameters)
    },
    clear() {
      this.receive({})
      this.changeFilters({})
      this.name = ''
    },
    search() {
      this.changeFilters({ ...this.parameters, filters: { ...(this.parameters?.filters || {}), name: this.name } })
    },
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
